  .s-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    background-color: #151515;
    overflow-y: scroll;
    padding: 20px;

  }

  .s-static-side,
  .s-scrolling-side {
    flex: 1;
    display: flex;
    background-color: #151515;
  }