.projects-container {
    height: 100%;
    padding: 88px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: black transparent;
    .projects {
        display: flex;
        flex-direction: column;
        
    }

    .github-placeholder {
        padding: 100px;
    }
}

.flex {
    display: flex;
    justify-items: center;
    justify-content: space-between;
}