.youtube-container {
}

.youtube-container.hovered {
    margin-bottom: 0px;
    margin-top: 0px;
    transform: scale(1.1);
}
.youtube-header{
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
}
.youtube-container {
    color: white;
    background-color: #f0f0f0;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    background-color: #f0f0f0;
    transition: all 0.2ss ease;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, .125);
    background-color: #1F2022;
    display: flex;
    justify-content: space-between;
    padding: 40px;
    margin: 4px;
}

.youtube-container.shedowed {
    background-color: #1B1B1C;
    color: #949495;
}

.youtube-internal {
    display: flex;
    flex-direction: column;
    align-items: self-start;
}

.video-text-container{
  .title{
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 900;
 }
 .video-discription{
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        font-feature-settings: normal;
        --tw-bg-opacity: 1;
        font-family: Helvetica;
        font-size: 16px;
        --tw-text-opacity: 1;
        color: rgb(148 148 149/var(--tw-text-opacity));
        box-sizing: border-box;
        border: 0 solid #e5e7eb;
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x: ;
        --tw-pan-y: ;
        --tw-pinch-zoom: ;
        --tw-scroll-snap-strictness: proximity;
        --tw-ordinal: ;
        --tw-slashed-zero: ;
        --tw-numeric-figure: ;
        --tw-numeric-spacing: ;
        --tw-numeric-fraction: ;
        --tw-ring-inset: ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgba(59, 130, 246, .5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur: ;
        line-height: 28px;
        font-weight: 600;
        font-family: Inter, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, sans-serif
 }
}
