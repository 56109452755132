.container {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  background-color: #151515;
  overflow: hidden;
}

.container {
  position: relative;
}

.container::before,
.container::after {
  content: "";
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.container::before {
  top: -10px;
  box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.8);
}

.container::after {
  bottom: -10px;
  box-shadow: 0px -4px 20px 2px rgba(0, 0, 0, 0.8);
}

.static-side,
.scrolling-side {
  flex: 1;
  display: flex;
}

.static-side {
  background-color: #151515;
  padding: 88px 0px 88px 88px;
  max-width: 30%;
}

.scrolling-side {
  background-color: #151515;

  max-width: 70%;
}
