.description {
    display: flex;
    align-items: center;
}

.contact {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.social-contacts {
    display: flex;
    flex-direction: row;
    color: white;
}

.header {
    font-weight: 700;
    font-size: 28px;
    color: #394145;
    padding-bottom: 20px;
}

.social-network-title {
    font-weight: 500;
}

.social-network-icon {
    font-size: 20px;
    padding-right: 15px;
}

.social-network {
    margin-left: 8px;

    &:hover {
        color: gray;
    }

    cursor: pointer;
}
.avatar {
    vertical-align: middle;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: black;
}
.text-area {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    padding-bottom: 20px;
    font-family: merriweather, serif;
}