.s-description {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.s-contact {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.s-social-contacts {
    display: flex;
    flex-direction: row;
    color: white;
}

.s-header {
    font-weight: 700;
    font-size: 28px;
    color: #394145;
    padding-bottom: 20px;
}

.s-social-network-title {
    font-weight: 500;
}

.s-social-network-icon {
    font-size: 20px;
    padding-right: 15px;
}

.s-social-network {
    margin-left: 8px;

    &:hover {
        color: gray;
    }

    cursor: pointer;
}
.s-avatar {
    vertical-align: middle;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: black;
}
.s-text-area {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    padding-bottom: 20px;
    font-family: merriweather, serif;
}

.s-scocial-phone-container{
    display: flex;
    align-items: center;
    margin-right:20px ;
}

.s-scocial-phone-container-text{
    font-size: 14px;
    font-weight: 800;
}
