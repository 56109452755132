.social-network-icon-small {
    font-size: 20px;
    padding-right: 14px;
}

.social-network-icon-large {
    font-size: 40px;
}

.social-network-icon-x-large{
    font-size: 100px;
}
.social-network {
    margin-left: 8px;
    &:hover {
        color: gray;
    }

    cursor: pointer;
}