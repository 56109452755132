.s-project-line {
    display: flex;
    margin-top: 8px;
}

.s-gh-project-container {
    color: white;
    background-color: #f0f0f0;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, .125);
    background-color: #1F2022;
    flex-direction: column;
    padding: 30px;
    margin: 4px;

    .s-btn {
        padding: 5px;

        &:hover {
            color: gray;
        }
    }

    .s-stats {
        display: flex;
        .s-star {
            font-size: 14px;
            margin-left: 12px;
        }
    }

    .s-wrapper {
        display: flex;
        font-size: 10px;
        .s-count {
            margin-left: 6px;
        }
    }

    .s-languages {
        display: flex;
        font-size: 12px;
        font-weight: 800;
    }

    .s-language {
        margin-right: 5px;
    }

    .s-card-header {
        display: flex;
        max-width: 200px;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;
        margin-top: 16px;
        color: #949495;
    }
    .s-project-icon {
        font-size: 40px;
        display: flex;
    }
    .s-project-name {
        font-size: 24px;
        font-weight: 800;
        line-height: 1.5;
        line-break: auto;
    }
    .s-project-description{
        color: #949495;
        max-width: 80%;
        font-size: 14px;

    }
}