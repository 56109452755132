.gh-project-container.hovered {
    margin-bottom: 0px;
    margin-top: 0px;
    transform: scale(1.1);
}

.project-line {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.gh-project-container {
    color: white;
    background-color: #f0f0f0;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    background-color: #f0f0f0;
    transition: all 0.2ss ease;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, .125);
    height: 40%;
    background-color: #1F2022;
    flex-direction: column;
    padding: 40px;
    margin: 4px;

    .btn {
        padding: 5px;

        &:hover {
            color: gray;
        }
    }

    .stats {
        display: flex;

        .star {
            font-size: 14px;
        }
    }

    .wrapper {
        display: flex;
        padding-right: 5px;

        .count {
            margin-left: 5px;
        }
    }

    .languages {
        display: flex;
        font-size: 12px;
        font-weight: 800;
    }

    .language {
        margin-right: 5px;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin-top: 16px;
        color: #949495;
    }

    .project-icon {
        font-size: 40px;
        display: flex;
    }

    .project-name {
        font-size: 25px;
        font-weight: 700;
        line-height: 1.5;
        margin-top: 8px;
        max-width: 500px;
    }
    .project-description{
        color: #949495;

    }
}

.gh-project-container.shedowed {
    background-color: #1B1B1C;
    color: #949495;
}